/* eslint-disable camelcase */
/* Many of these values come directly from Drupal, which prefers camelCase. */
import React, { useState } from 'react';

import Modal from '../../Modal/Modal';
import { bedBathSqftArray } from '../../../utils/common';

import './UnitShape.scss';

const UnitShape = ( {
  svgData,
  unitBaseStyling,
} ) => {
  const [ showModal, setShowModal ] = useState( false );

  const handleModalShow = () => setShowModal( true );
  const handleModalHide = () => setShowModal( false );

  const { type, info, attributes , displayMode} = svgData;
  const { id, transform, x, y, width, height, points, d } = attributes;
  const { uuid, studio, key_color, beds, baths, sqft, label_text, floor_plan } = info;

  // Set fill and stroke based on display mode.
  let fill = unitBaseStyling?.neutralUnitFill;
  let stroke = unitBaseStyling?.neutralUnitStroke;
  switch ( displayMode ) {
    case 'active':
      fill = key_color;
      stroke = unitBaseStyling?.neutralUnitStroke;
      break;
    case 'inactive':
      fill = unitBaseStyling.neutralUnitStroke;
      stroke = key_color;
      break;
    case 'disabled':
      fill = unitBaseStyling?.neutralUnitFill;
      stroke = unitBaseStyling?.neutralUnitStroke;
      break;
    default:
      fill = unitBaseStyling?.neutralUnitFill;
      stroke = unitBaseStyling?.neutralUnitStroke;
      break;
  }

  // Create base for all shapes.
  const commonShapeProps = {
    id,
    className: 'unit',
    fill,
    stroke,
    onClick: displayMode !== 'disabled' ? handleModalShow : null,
  };

  if ( transform ) {
    commonShapeProps.transform = transform;
  }

  let unitShape = null;
  switch ( type ) {
    case 'polygon':
      unitShape = <polygon points={points} {...commonShapeProps} />;
      break;
    case 'polyline':
      unitShape = <polyline points={points} {...commonShapeProps} />;
      break;
    case 'path':
      unitShape = <path d={d} {...commonShapeProps} />;
      break;
    case 'rect':
      unitShape = (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          {...commonShapeProps}
        />
      );
      break;
    default:
      break;
  }

  // the svg array stringified the studio value, lets turn it back in to a boolean
  const booleanStudio = studio === '1' ? 1 : 0;
  const bedBathSqft = info ? bedBathSqftArray( booleanStudio, beds, baths, sqft ) : '';

  const modalInfo = {
    title: label_text,
    fpName: floor_plan?.title || '',
    bedBathSqft,
  };

  return (
    <>
      { unitShape }

      { showModal && (
        <Modal
          nodeType="residence"
          uuid={uuid}
          showDescription
          modalInfo={modalInfo}
          handleModalHide={handleModalHide}
        />
      )}
    </>
  );
};

export default UnitShape;
