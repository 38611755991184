import React from 'react';

const HideMarkersIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <path d="M19.000244,0.136475c-10.401367,0-18.86377,8.462402-18.86377,18.86377s8.462402,18.863281,18.86377,18.863281
    s18.863281-8.461914,18.863281-18.863281S29.401611,0.136475,19.000244,0.136475z M19.000244,3.136475
    c3.799744,0,7.289612,1.345642,10.02417,3.581787l-5.089478,5.089478l-3.325562-3.23938H7.079041
    C9.988953,5.247253,14.248291,3.136475,19.000244,3.136475z M4.994446,11.568359h14.395691l2.423523,2.360657l-2.106812,2.106812
    C19.479004,15.981628,19.244446,15.945435,19,15.945435c-1.686951,0-3.054565,1.367554-3.054565,3.054565
    c0,0.244446,0.036133,0.479004,0.090393,0.706848l-6.724792,6.724792H5.004761
    c-1.184631-2.21936-1.868286-4.74469-1.868286-7.431396C3.136475,16.31543,3.812195,13.787292,4.994446,11.568359z
    M18.085266,21.899597C18.375793,21.991333,18.679138,22.054504,19,22.054504c1.686951,0,3.054565-1.367554,3.054565-3.054504
    c0-0.320923-0.063171-0.624207-0.154907-0.914795l2.062927-2.062927L27.019531,19l-7.629395,7.431641h-5.836914L18.085266,21.899597
    z M19.000244,34.863525c-3.869324,0-7.416077-1.396301-10.172424-3.706482l1.725403-1.725403h10.056152L31.318359,19
    l-5.234558-5.098938l5.073242-5.073242c2.310181,2.756348,3.706482,6.303101,3.706482,10.172424
    C34.863525,27.747314,27.747314,34.863525,19.000244,34.863525z"
    />
  </svg>
);

export default HideMarkersIcon;
