import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import $ from 'jquery';
import 'jquery-easing';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import FadingContainer from '../FadingContainer/FadingContainer';

const ModalHeader = ( {
  title,
  navBtns,
  handleModalHide
} ) => {
  const [ titleHeight, setTitleHeight ] = useState( 0 );

  const {
    unitHasDisplayableFees,
    displayModalPricing,
    featureFlags,
    isKiosk,
    isMapEmbed,
    config,
  } = useContext(
    ConfigContext,
  );

  const {
    isPIB,
  } = config;

  // What should we show? We have to take into account the Map Page, Kiosk Map
  // Page, SiteSee, and PIB.
  let showPricing = false;
  if ( isKiosk ) {
    showPricing = featureFlags?.includes( 'kiosk_show_pricing' );
  } else if ( isMapEmbed ) {
    showPricing = featureFlags?.includes( 'sitesee_show_pricing' );
  } else if ( isPIB ) {
    showPricing = featureFlags?.includes( 'pib_show_pricing' );
  } else {
    showPricing = featureFlags?.includes( 'yt_show_pricing' );
  }

  const handleModalNavClick = ( section ) => {
    const sectionDiv = document.getElementById( section );
    // we'll need this when we factor out the jquery
    // const offset = sectionDiv.getBoundingClientRect().top;
    // const modalDiv = document.getElementsByClassName('modal')[0];
    // @TODO: Refactor to remove jQuery.
    if ( sectionDiv ) {
      $( '.modal' ).animate(
        {
          scrollTop:
            $( sectionDiv ).offset().top - titleHeight + $( '.modal' ).scrollTop(),
        },
        500,
        'easeInOutCubic',
      );
    } else {
      // eslint-disable-next-line no-console
      console.log( 'DEBUG: Section passed does not exist on page!', section );
    }
  };

  useEffect( () => {
    modalMargin( titleHeight );
  }, [ titleHeight ] );

  const modalHeaderElement = useRef( null );
  // you have to watch nav too so it will take into account the nav button height
  useEffect( () => {
    if ( modalHeaderElement.current ) {
      const modalHeaderHeight = modalHeaderElement.current.clientHeight;
      setTitleHeight( modalHeaderHeight );
    }
  }, [ modalHeaderElement, navBtns.length ] );

  let titleMarkup = '';
  // if there's only 1 property and its title.title
  if ( title.length === 1 && title.title ) {
    titleMarkup = <h1 className="modalTitle">{title.title}</h1>;
  } else {
    titleMarkup = (
      <>
        <h1 className="modalTitle">

          <span className="modalTitle__featured">
            {title?.title}
            {' '}
          </span>

          { title.fpName && title.fpName !== '' && (
            <>
              -
              {' '}
              {title.fpName}
            </>
          )}

          { title?.tags?.map( ( item ) => (
            <span
              key={item.type}
              className={`modalTitle__tag modalTitle__tag--${item.type}`}
            >
              {item.text}
            </span>
          ) )}

          {/* <span className="modalTitle--note">Reversed</span> */}
        </h1>

        { title?.bedBathSqft?.length > 0 && (

          <h2 className="modalSubtitle">
            {title.bedBathSqft.map( ( item, i ) => (
              <span
                key={i}
                className="modalSubtitle__group"
              >
                {item}
              </span>
            ) )}

            { unitHasDisplayableFees && showPricing && (
              <span className="modalSubtitle__group modalSubtitle__group__displayFees">
                <button
                  type="button"
                  className="modalSubtitle__displayFees type-display-lg"
                  onClick={() => {
                    displayModalPricing();
                  }}
                >
                  more
                </button>
              </span>
            )}

          </h2>
        )}
      </>
    );
  }

  return (
    <div className="modalHeader" ref={modalHeaderElement}>
      <div className="modalTitleArea">{titleMarkup}</div>

      {navBtns?.length > 1 && (
        <FadingContainer>
          <div className="modalNav">
            {navBtns.map( ( btn ) => (
              <button
                type="button"
                className="modalNavItem"
                key={btn.section}
                section={btn.section}
                onClick={() => handleModalNavClick( btn.section )}
              >
                {btn.text}
              </button>
            ) )}
          </div>
        </FadingContainer>
      )}

      <button
        type="button"
        className="modalClose"
        onClick={handleModalHide}
      >
        <span />
      </button>
    </div>
  );
};

// @TODO: Refactor into Modal component and remove jQuery.
function modalMargin( headerHeight ) {
  $( '.modalContent' ).css( 'margin-top', headerHeight );
}

export default ModalHeader;
