import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import PageFilters from '../Page/PageFilters/PageFilters';
import FloorPlansList from './FloorPlansList/FloorPlansList';
import { getNormalizedApiData } from '../../utils/ApiHelpers';
import { floorPlansNormalizeData } from './FloorPlansList/FloorPlansListNormalizeData';
import MyLifeSiteLink from '../MyLifeSiteLink/MyLifeSiteLink';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
// Import MapContext since we're using very similar filters as the map page.
import MapContext from '../MapPage/MapContext';

import '../../GlobalScss/grid.scss';
import './FloorPlansPage.scss';
import '../MapPage/MapFilterMenu/MapFilterMenu.scss';

const FloorPlansPage = () => {
  const [ items, setItems ] = useState( [] );
  const [ filteredItems, setFilteredItems ] = useState( [] );
  const [ activeFilterSelections, setActiveFilterSelections ] = useState( {} );
  const [ activeLevelOfCareUuid, setActiveLevelOfCareUuid ] = useState( null );
  const [ activeCategoryFilter, setActiveCategoryFilter ] = useState( [] );
  const [ resultFloorPlans, setResultFloorPlans ] = useState( [] );

  const { config } = useContext( ConfigContext );
  const { communityNid, enableMyLifeSite, myLifeSiteUrl } = config;

  // Get all floor plan data.
  useEffect( () => {
    const fetchData = async () => {
      const includes = [
        'field_2d_floor_plan',
        'field_3d_floor_plan',
        'field_level_of_care',
        'field_finish_collection',
        'field_finish_collection.field_finish_option',
        'field_finish_collection.field_finish_option.field_media_image',
        'field_finish_collection.field_finish_option.field_finish_space',
        'field_finish_collection.field_finish_option.field_finish_palette',
        'field_floor_plan_category',
        'field_virtual_tour',
        'field_virtual_tour.field_three_sixties',
        'field_video',
        'field_photo_gallery',
        'field_photo_gallery.field_gallery_photos',
      ];
      const request = {
        entityType: 'node',
        contentType: 'floor_plan',
        params: {
          sort: 'field_display_order,title',
          include: includes.join( ',' ),
        },
        filter: {
          field_display_on_floor_plans_pag: 1,
        },
      };
      const data = await getNormalizedApiData( request, communityNid, floorPlansNormalizeData );
      const { floorPlans } = data || {};
      setItems( floorPlans );
    };
    fetchData();
  }, [ communityNid ] );

  /**
   * Helper function to filter floor plan results.
   *
   * @param {array} filterOption
   *   Array of Floor plan UUIDs to filter by.
   */
  const filterResults = ( filterOption ) => {
    // If there's no filter option set, return all the results.
    if ( !filterOption ) {
      setFilteredItems( items );
    } else {
      const result = items.filter( ( item ) => filterOption.indexOf( item.id ) >= 0 );
      // Floor plan results after being filtered.
      setFilteredItems( result );
    }
  };

  /**
   * When data is loaded from API (items), 'filter' results to set initial
   * state.
   */
  useEffect( () => {
    filterResults();
  }, [ items ] );

  useEffect( () => {
    filterResults( resultFloorPlans );
  }, [ resultFloorPlans ] );

  return (
    <MapContext.Provider
      value={{
        activeFilterSelections,
        setActiveFilterSelections,
        activeLevelOfCareUuid,
        setActiveLevelOfCareUuid,
        activeCategoryFilter,
        setActiveCategoryFilter,
        resultFloorPlans,
        setResultFloorPlans,
      }}
    >
      <div className="content floorplans">

        {filteredItems?.length === 0
        && <LoadingSpinner />}

        <PageFilters
          filterResults={filterResults}
          hideByFieldName="field_display_on_floor_plans_pag"
        />

        <FloorPlansList
          floorPlans={filteredItems}
        />

        { enableMyLifeSite && myLifeSiteUrl && (
          <MyLifeSiteLink
            url={myLifeSiteUrl}
          />
        )}

      </div>
    </MapContext.Provider>
  );
};

export default FloorPlansPage;
