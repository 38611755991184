import React, {
  useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Transition } from 'react-transition-group';
import { calcPathCenterCoords } from '../../MapHelpers';
import MapContext from '../../../MapPage/MapContext';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';

import Modal from '../../../Modal/Modal';

import './MapPlaceLabel.scss';

const MapPlaceLabel = ( { svgData } ) => {
  const [ labelScale, setLabelScale ] = useState( 1 );
  const [ placeX, setPlaceX ] = useState( 0 );
  const [ placeY, setPlaceY ] = useState( 0 );
  const [ labelOri, setLabelOri ] = useState( 'left' );
  const [ isLink, setIsLink ] = useState( false );
  const [ modalTitle, setModalTitle ] = useState( false );
  const [ textX, setTextX ] = useState( 0 );
  const [ textY, setTextY ] = useState( 0 );
  const [ textDy, setTextDy ] = useState( 0 );
  const [ bgX, setBgX ] = useState( 0 );
  const [ bgY, setBgY ] = useState( 0 );
  const [ bgWidth, setBgWidth ] = useState( 0 );
  const [ bgHeight, setBgHeight ] = useState( 0 );
  const [ showModal, setShowModal ] = useState( false );
  const [ showNonRichLabel, setShowNonRichLabel ] = useState( false );
  const [ showNonRichLabelInitially, setShowNonRichLabelInitially ] = useState( false );

  const {
    initialOverlayWidth,
    currentOverlayWidth,
    markersVisible,
  } = useContext( MapContext );

  const {
    config,
    settings,
  } = useContext( ConfigContext );

  const { features } = config;
  const { isKiosk, isMapEmbed } = settings;

  const labelTextRef = useRef();
  const labelBgRef = useRef();
  const nonRichMediaDotRef = useRef();
  const nonRichModalLabelRef = useRef( null );

  // Should we initially expand lower value labels, according to feature flags?
  const kioskFlag = features?.includes( 'kiosk_show_lower_value_place_labels_initially' );
  const mapEmbedFlag = features?.includes( 'sitesee_show_lower_value_place_labels_initially' );
  const ytFlag = features?.includes( 'yt_show_lower_value_place_labels_initially' );
  useEffect( () => {
    if ( isKiosk && kioskFlag ) {
      setShowNonRichLabelInitially( true );
    } else if ( isMapEmbed && mapEmbedFlag ) {
      setShowNonRichLabelInitially( true );
    } else if ( ytFlag ) {
      setShowNonRichLabelInitially( true );
    }
  }, [
    kioskFlag,
    mapEmbedFlag,
    ytFlag,
    isKiosk,
    isMapEmbed,
  ] );

  // how much do we need to scale the label?
  useLayoutEffect( () => {
    if ( initialOverlayWidth && currentOverlayWidth ) {
      const ratio = initialOverlayWidth / currentOverlayWidth;
      setLabelScale( ratio );
    }
  }, [ initialOverlayWidth, currentOverlayWidth ] );

  // get precalculated cords for place pin position for svg data
  useLayoutEffect( () => {
    if ( svgData ) {
      let rawCenterCoords = null;
      if ( svgData.type === 'path' ) {
        rawCenterCoords = calcPathCenterCoords( svgData.attributes.d );
      } else if ( svgData.center ) {
        rawCenterCoords = svgData.center;
      }
      if ( rawCenterCoords ) {
        const x = rawCenterCoords.x / labelScale;
        const y = rawCenterCoords.y / labelScale;
        setPlaceX( x );
        setPlaceY( y );
      }
    }
  }, [ svgData, labelScale ] );

  // get pin info from svg data
  useEffect( () => {
    const rawPin = svgData?.pin;
    if ( rawPin ) {
      const rawLabelOri = rawPin.label_orientation || 'left';
      const rawIsLink = rawPin.create_modal || false;
      const rawModalTitle = rawPin.modal_title || '';
      const rawShowLabel = rawPin.show_label || false;
      setLabelOri( rawLabelOri );
      setIsLink( rawIsLink );
      setModalTitle( rawModalTitle );
      setShowNonRichLabel( rawShowLabel );
    }
  }, [ svgData ] );

  const modalInfo = {
    // has to be object, not string
    title: {
      title: modalTitle,
    },
  };

  const offset = 5;
  const horiPadding = 5;
  const vertPadding = 10;
  const circleRadius = 7;

  // calculate text x y, taking in to account pin class and padding for offset
  useLayoutEffect( () => {
    let rawTextX = placeX;
    let rawTextY = placeY;
    let rawTextDy = 0;
    switch ( labelOri ) {
      case 'left':
        rawTextX = placeX - circleRadius - offset - vertPadding;
        rawTextDy = '0.3em';
        break;
      case 'right':
        rawTextX = placeX + circleRadius + offset + vertPadding;
        rawTextDy = '0.3em';
        break;
      case 'top':
        rawTextY = placeY - circleRadius - offset - ( horiPadding * 2 );
        break;
      case 'bottom':
        rawTextY = placeY + circleRadius + offset + horiPadding;
        rawTextDy = '1em';
        break;
      default:
        break;
    }
    setTextX( rawTextX );
    setTextY( rawTextY );
    setTextDy( rawTextDy );
  }, [
    placeX, placeY, labelOri,
  ] );

  // calculate label bg from text label
  useLayoutEffect( () => {
    if ( labelTextRef.current ) {
      // get dims of text label
      const liveLabelTextDims = labelTextRef.current.getBBox();
      const rawBgHeight = liveLabelTextDims.height + ( horiPadding * 2 );
      const rawBgWidth = liveLabelTextDims.width + ( vertPadding * 2 );
      let rawBgX = textX;
      let rawBgY = textY;

      switch ( labelOri ) {
        case 'left':
          rawBgX = textX - liveLabelTextDims.width - vertPadding;
          rawBgY = textY - ( liveLabelTextDims.height / 2 ) - horiPadding;
          break;
        case 'right':
          rawBgX = textX - vertPadding;
          rawBgY = textY - ( liveLabelTextDims.height / 2 ) - horiPadding;
          break;
        case 'top':
          rawBgX = textX - ( liveLabelTextDims.width / 2 ) - vertPadding;
          rawBgY = textY - liveLabelTextDims.height;
          break;
        case 'bottom':
          rawBgX = textX - ( liveLabelTextDims.width / 2 ) - vertPadding;
          rawBgY = textY - horiPadding;
          break;
        default:
          break;
      }
      setBgX( rawBgX );
      setBgY( rawBgY );
      setBgHeight( rawBgHeight );
      setBgWidth( rawBgWidth );
    }
  }, [
    labelTextRef.current, textX, textY, labelOri, markersVisible,
  ] );

  // modal
  const handleModalHide = () => setShowModal( false );

  if ( labelBgRef.current && svgData?.pin?.create_modal ) {
    labelBgRef.current.onclick = () => {
      setShowModal( true );
    };
  }

  if ( !isLink && nonRichMediaDotRef.current && svgData && !showNonRichLabelInitially ) {
    nonRichMediaDotRef.current.onclick = () => {
      setShowNonRichLabel( true );
    };
  }

  const duration = 500;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    pointerEvents: 'none',
  };

  const transitionStyles = {
    entering: { opacity: 1, pointerEvents: 'all' },
    entered: { opacity: 1, pointerEvents: 'all' },
    exiting: { opacity: 0, pointerEvents: 'none' },
    exited: { opacity: 0, pointerEvents: 'none' },
  };

  return (
    <>
      { markersVisible
        && (
        <g
          style={{
            transform: `scale(${labelScale})`,
          }}
          className={
            classnames( 'pMarker', {
              'pMarker--link': isLink,
            } )
          }
        >
          <circle
            cx={placeX}
            cy={placeY}
            r={circleRadius}
            className="pMarker__dot"
            ref={nonRichMediaDotRef}
          />

          {
            !isLink && (
              <text
                x={placeX - 4.75}
                y={placeY + 5.8}
                ref={nonRichMediaDotRef}
              >
                <tspan className={
                  classnames( 'pMarker__trigger', {
                    pMarker__trigger__place: true,
                    show__nonRichLabel: showNonRichLabelInitially ? true : showNonRichLabel,
                  } )
                }
                >
                  +
                </tspan>
              </text>
            )
          }

          {!isLink && (
            <Transition
              nodeRef={nonRichModalLabelRef}
              in={showNonRichLabelInitially ? true : showNonRichLabel}
              timeout={duration}
            >

              { ( state ) => (
                <g
                  ref={nonRichModalLabelRef}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  <rect
                    rx="8"
                    ry="8"
                    x={bgX}
                    y={bgY}
                    width={bgWidth}
                    height={bgHeight}
                    className={
                      classnames( 'pMarker__bg', {
                        pMarker__bg__nonRich: true,
                        show__nonRichLabel: showNonRichLabelInitially ? true : showNonRichLabel,
                      } )
                    }
                    ref={labelBgRef}
                  />
                  <text
                    x={textX}
                    y={textY}
                    className={
                      classnames( `pMarker__text pMarker__text--${labelOri}`, {
                        pMarker__text__nonRich: true,
                        show__nonRichLabel: showNonRichLabelInitially ? true : showNonRichLabel,
                      } )
                    }
                    ref={labelTextRef}
                  >
                    {/*
                      baseline isn't supported across all browers
                      so we have to fake it with an empty starting tspan and
                      dy in following tspan
                    */}
                    <tspan />

                    <tspan dy={textDy}>{svgData.pin.label_text}</tspan>
                  </text>
                </g>
              )}

            </Transition>
          )}

          {isLink && (
            <>
              <rect
                rx="8"
                ry="8"
                x={bgX}
                y={bgY}
                width={bgWidth}
                height={bgHeight}
                className={
                  classnames( 'pMarker__bg', {
                    pMarker__bg__nonRich: !isLink,
                    show__nonRichLabel: showNonRichLabel,
                  } )
                }
                ref={labelBgRef}
              />

              <text
                x={textX}
                y={textY}
                className={
                  classnames( `pMarker__text pMarker__text--${labelOri}`, {
                    pMarker__text__nonRich: !isLink,
                    show__nonRichLabel: showNonRichLabel,
                  } )
                }
                ref={labelTextRef}
              >
                {/*
                  baseline isn't supported across all browers
                  so we have to fake it with an empty starting tspan and
                  dy in following tspan
                */}
                <tspan />

                { !isLink && (
                  <tspan dy={textDy}>{svgData.pin.label_text}</tspan>
                )}

                {isLink
                  && (
                  <>
                    <tspan dy={textDy}>{svgData.pin.label_text}</tspan>
                    <tspan className="pMarker__text__divider">&nbsp;|&nbsp;</tspan>
                    <tspan className="pMarker__trigger">+</tspan>
                  </>
                  )}

              </text>
            </>
          )}
        </g>
        )}

      {showModal
        && (
        <Modal
          nodeType="place"
          uuid={svgData.pin.uuid}
          showDescription
          modalInfo={modalInfo}
          handleModalHide={handleModalHide}
        />
        )}

    </>
  );
};

// MapPlaceLabel.propTypes = {
//   svgData: PropTypes.objectOf( {
//     attributes: PropTypes.objectOf( PropTypes.string ),
//     center: {
//       x: PropTypes.number,
//       y: PropTypes.number,
//     },
//     pin: {
//       svg_id: PropTypes.string.isRequired,
//       create_modal: PropTypes.bool.isRequired,
//       modal_title: PropTypes.string.isRequired,
//       uuid: PropTypes.string.isRequired,
//       label_text: PropTypes.string.isRequired,
//       label_orientation: PropTypes.string.isRequired,
//       pin_type: PropTypes.string.isRequired,
//     },
//     type: PropTypes.string.isRequired,
//   } ).isRequired,
// };

export default MapPlaceLabel;
