import React from 'react';

const HybridIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <path
      d="M19,0.1C8.6,0.1,0.1,8.6,0.1,19S8.6,37.9,19,37.9S37.9,29.4,37.9,19S29.4,0.1,19,0.1z M33.1,11.8c-2.4,0.2-4.3-0.2-5.5-1.4
        c-1.7-1.6-1.9-4.2-1.9-5.8C28.9,6.2,31.5,8.7,33.1,11.8z M11.4,5.1c0.1,2.4,0.8,3.6,1.8,4.8c0.6,0.8,1.2,1.5,1.7,3.1
        c0.8,3.1,0.2,3.8-1,5.1c-0.3,0.3-0.7,0.7-1,1.2c-0.8,1.1-1,2-1.1,2.8c-0.2,1.1-0.4,2-2.2,3.5c-2.2,1.8-3.6,1.8-4,1.7
        C4,25,3.1,22.1,3.1,19C3.1,13,6.5,7.8,11.4,5.1z M7.8,30.2c1.1-0.3,2.3-1,3.8-2.1c2.7-2.2,3-3.9,3.2-5.3c0.1-0.7,0.2-1.1,0.6-1.6
        c0.3-0.4,0.5-0.7,0.8-1c1.4-1.5,2.9-3.3,1.6-7.9C17.2,10,16.3,8.9,15.6,8c-0.8-1-1.3-1.7-1.1-4.2c1.4-0.4,3-0.7,4.6-0.7
        c1.3,0,2.6,0.2,3.8,0.5c-0.1,1.7-0.2,6.2,2.7,9c1.6,1.5,3.7,2.3,6.4,2.3c0.7,0,1.5-0.1,2.3-0.2c0.4,1.4,0.6,2.8,0.6,4.3
        c0,0.2,0,0.5,0,0.7c-2.2-0.4-5.1-0.2-6.8,1.6c-1.5,1.5-2.5,1.6-2.5,1.6c-0.2,0-6.1-0.1-8,4.9c-1.1,2.8,0.4,5.3,2.3,7.1
        c-0.3,0-0.6,0-0.9,0C14.6,34.9,10.6,33.1,7.8,30.2z M23.9,34.1c-1.1-0.6-4.4-2.9-3.5-5.2c1.2-3,5-3,5.1-3c0.4,0,2.4,0,4.8-2.5
        c0.9-1,3-0.9,4.2-0.7C33.1,28,29.1,32.4,23.9,34.1z"
    />
  </svg>
);
export default HybridIcon;
