import React, { useContext, useEffect, useState } from 'react';

import MapContext from '../../MapPage/MapContext';
import MapControlsZoom from './MapControlsZoom/MapControlsZoom';
import MapControlsMapTypeToggle from './MapControlsMapTypeToggle/MapControlsMapTypeToggle';
import MapControlsPOIToggle from './MapControlsPOIToggle/MapControlsPOIToggle';
import MapControlsMarkersToggle from './MapControlsMarkersToggle/MapControlsMarkersToggle';
import './MapControls.scss';

const MapControls = ( {
  setActiveZoom,
  activeZoom,
  zoomSettings,
  setFitAndPan,
  setOverlayVisible,
  setMarkersVisible,
  markersToggleVisible,
} ) => {
  const [ enablePOIToggle, setEnablePOIToggle ] = useState( false );

  const {
    mapData,
  } = useContext( MapContext );

  const {
    googleMapsConfig,
  } = mapData;

  useEffect( () => {
    if ( googleMapsConfig ) {
      const { enablePOI } = googleMapsConfig;
      if ( enablePOI ) {
        setEnablePOIToggle( true );
      }
    }
  }, [ googleMapsConfig ] );

  return (
    <div className="mapControls">

      <MapControlsZoom
        setActiveZoom={setActiveZoom}
        activeZoom={activeZoom}
        zoomSettings={zoomSettings}
      />

      <MapControlsMapTypeToggle />

      { enablePOIToggle
        && <MapControlsPOIToggle /> }

      <MapControlsMarkersToggle
        setMarkersVisible={setMarkersVisible}
        markersToggleVisible={markersToggleVisible}
      />

    </div>
  );
};

export default MapControls;
