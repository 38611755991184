import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import Portal from '../../utils/Portal';

import './Modal.scss';

const ModalLite = (props) => {
  const [ modalScrollActive, setModalScrollActive ] = useState(false);
  const {
    isOpen,
    handleModalHide,
    ModalHeader,
    modalSize
  } = props;

  const context = useContext(ConfigContext);
  const config = context.config;

  let containerStyle = {};
  if (config) {
    containerStyle = {
      '--primary': config.primaryColor,
      '--secondary': config.secondaryColor,
    };
  }

  const handleModalScroll = (event) => {
    if (event.currentTarget.scrollTop > 20) {
      setModalScrollActive(true);
    } else {
      setModalScrollActive(false);
    }
  }

  return (
    <>
    { isOpen && (
      <Portal portalId="modal-root">
          <div
            className={classnames("modalContainer", { "modal-scroll-active": modalScrollActive })}
            style={containerStyle}
          >
            <div className="blur closeMe" onClick={handleModalHide}></div>

            { ModalHeader && (<ModalHeader />)}

            <div
              className={classnames("modal", {
                "modal--small": modalSize === "small",
                "modal--medium": modalSize === "medium",
                "modal--floating": modalSize === "small" || modalSize === "medium"
              })}
              onScroll={handleModalScroll}
            >
              <div className="modalContent">
              { props.children }
              </div>
            </div>

          </div>
      </Portal>
      )}
    </>
  );
};

export default ModalLite;
