import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const EndPersonalizationWarning = ( {
  isSubmitting,
  setFormIsSubmitting,
} ) => {
  const handleClick = () => {
    setFormIsSubmitting( true );
  };

  return (
    <div className="personalization__not-found-step">

      <h3 className="personalization__form-header">
        <span>Stop</span>
        {' '}
        Personalization?
      </h3>

      <div className="personalization__instructions">
        Are you sure you want to stop personalization?
      </div>

      <button
        disabled={isSubmitting}
        onClick={handleClick}
        type="submit"
        className={classNames( 'personalization__cta personalization__cta--secondary', {
          'personalization__cta--working': isSubmitting,
        } )}
      >
        { isSubmitting ? 'Updating Prospect . . .' : 'Yes'}
      </button>

    </div>
  );
};

EndPersonalizationWarning.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  setFormIsSubmitting: PropTypes.func.isRequired,
};

export default EndPersonalizationWarning;
