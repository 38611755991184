import * as yup from 'yup';

// We're pushing separate validations as array entries to match each form step.
const personalizationValidationSchema = [];

// STEP 1 Validation: Account Lookup Step.
personalizationValidationSchema.push( yup.object().shape( {
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().when( [ 'last_name', 'first_name' ], {
    is: ( last_name, first_name ) => {
      let fieldsEmpty = false;

      if (
        ( last_name === undefined || last_name.length === 0 )
        && ( first_name === undefined || first_name.length === 0 )
      ) {
        fieldsEmpty = true;
      }
      // Return true IF Last name & Email are both blank.
      return fieldsEmpty;
    },
    then: yup.string().required( 'Enter a first name and/or a last name and/or an email address.' ),
    otherwise: yup.string().email( 'Make sure to type a valid email address' ),
  } ),
} ) );

// STEP 2 Validation: Account selection step in a situation where multiple
// records are found. If "Create New" is not trigger, it is required.
personalizationValidationSchema.push( yup.object().shape( {
  action: yup.string(),
  residentSelection: yup.string().when( 'action', {
    is: ( action ) => {
      const isNotCreateNew = action !== 'create new';
      return isNotCreateNew;
    },
    then: yup.string().required( 'Select one of the entries from the list above before clicking the CONTINUE button. If the person you are looking for is not listed, click the CREATE NEW link.' ),
    otherwise: yup.string(),
  } ),
} ) );

// STEP 3 Validation: User not found validation.
// There aren't any fields to validate, but this is needed to line up
// validation with form steps.
personalizationValidationSchema.push( yup.object().shape( {
} ) );

// STEP 4 Validation: Account selection step in a situation where multiple records are found.
personalizationValidationSchema.push( yup.object().shape( {
  email: yup.string().email( 'Make sure to type a valid email address' ).required( 'Make sure to type a valid email address' ),
  first_name: yup.string().required( 'Enter a first name.' ),
  last_name: yup.string().required( 'Enter a last name.' ),
  person2_first_name: yup.string().when( 'person2_email', {
    is: ( value ) => value && value.length > 0,
    then: yup.string().required(
      'Enter a first name',
    ),
    otherwise: yup.string(),
  } ).when( 'person2_last_name', {
    is: ( value ) => value && value.length > 0,
    then: yup.string().required(
      'Enter a first name',
    ),
    otherwise: yup.string(),
  } ),
  person2_last_name: yup.string().when( 'person2_email', {
    is: ( value ) => value && value.length > 0,
    then: yup.string().required(
      'Enter a last name',
    ),
    otherwise: yup.string(),
  } ).when( 'person2_first_name', {
    is: ( value ) => value && value.length > 0,
    then: yup.string().required(
      'Enter a last name',
    ),
    otherwise: yup.string(),
  } ),
  person2_email: yup.string().email( 'Make sure to type a valid email address' ),
}, [ 'person2_first_name', 'person2_last_name' ] ) );

export default personalizationValidationSchema;
