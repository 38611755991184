import React, { useContext, useEffect, useState } from 'react';

import MapContext from '../../MapPage/MapContext';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';

import UnitLabel from '../MapUnit/UnitLabel';
import PlaceLabel from '../MapPlace/MapPlaceLabel/MapPlaceLabel';

const MapLevelLabels = ( {
  units,
  places,
} ) => {
  const [ showOccupancy, setShowOccupancy ] = useState( true );
  const {
    isMapEmbed,
  } = useContext( MapContext );

  const {
    featureFlags,
    isKiosk,
  } = useContext( ConfigContext );

  // Should we show occupancy status? This won't be on PIB.
  const rawKioskShowOccupancy = featureFlags?.includes( 'kiosk_show_occupancy' );
  const rawSiteSeeShowOccupancy = featureFlags?.includes( 'sitesee_show_occupancy' );
  const rawYtShowOccupancy = featureFlags?.includes( 'yt_show_occupancy' );
  useEffect( () => {
    let rawShowOccupancy = true;
    if ( isKiosk ) {
      rawShowOccupancy = rawKioskShowOccupancy;
    } else if ( isMapEmbed ) {
      rawShowOccupancy = rawSiteSeeShowOccupancy;
    } else {
      rawShowOccupancy = rawYtShowOccupancy;
    }
    setShowOccupancy( rawShowOccupancy );
  }, [
    isKiosk,
    isMapEmbed,
    rawKioskShowOccupancy,
    rawSiteSeeShowOccupancy,
    rawYtShowOccupancy,
  ] );

  return (
    <g>

      { units?.length > 0 && units.map( ( unit ) => (
        <UnitLabel
          key={unit.info.uuid}
          svgData={unit}
          showOccupancy={showOccupancy}
        />
      ) )}

      { places?.length > 0 && places.map( ( place ) => (
        <PlaceLabel
          key={place.pin.uuid}
          svgData={place}
        />
      ) )}

    </g>
  );
};

export default MapLevelLabels;
