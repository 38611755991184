import {
  processPriceFieldWithOptionalRangeSerialized,
} from '../../../utils/common';
import { processSerializedThreeSixties } from '../../VirtualTour/VirtualTourDetailNormalizeData';
import { finishCollectionNormalizeData } from '../../FinishCollection/FinishCollectionNormalizeData';

const JSONAPIDeserializer = require( 'jsonapi-serializer' ).Deserializer;

// eslint-disable-next-line import/prefer-default-export
export const floorPlansNormalizeData = async ( responseData ) => {
  const options = {
    keyForAttribute: 'camelCase',
  };

  const deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );
  const levelsOfCare = {};
  const floorPlanCategories = {};
  let defaultLevelOfCare = '';
  const included = responseData?.included;

  // Massage the data into the shape we care about for floorplans.
  const floorPlans = deserializedData.map( ( fp, index ) => {
    const unserializedFp = responseData.data[index];

    // 2d floorplan
    let twoDFloorPlan = null;
    const twoDFpFieldData = unserializedFp.relationships.field_2d_floor_plan.data;
    if ( twoDFpFieldData ) {
      twoDFloorPlan = {
        oneThirdScreen: twoDFpFieldData?.meta?.imageDerivatives?.links?.one_third_screen?.href,
        modal: twoDFpFieldData?.meta?.imageDerivatives?.links?.modal?.href,
      };
    }

    // 3d floorplan
    let threeDFloorPlan = null;
    const threeDFpFieldData = unserializedFp.relationships.field_3d_floor_plan.data;
    if ( threeDFpFieldData ) {
      threeDFloorPlan = {
        oneThirdScreen: threeDFpFieldData?.meta?.imageDerivatives?.links?.one_third_screen?.href,
        modal: threeDFpFieldData?.meta?.imageDerivatives?.links?.modal?.href,
      };
    }

    levelsOfCare[fp.fieldLevelOfCare?.id] = {
      name: fp.fieldLevelOfCare?.name,
      weight: fp.fieldLevelOfCare?.weight,
      id: fp.fieldLevelOfCare?.id,
      isDefault: fp.fieldLevelOfCare?.fieldDefaultLevelOfCare,
    };

    // this needs to be reworked to take into account multiple categories per floor plan
    // add 1 to the weight because "all floor plans" gets the 0 weight
    floorPlanCategories[fp.fieldFloorPlanCategory?.[0]?.id] = {
      name: fp.fieldFloorPlanCategory?.[0]?.name,
      weight: fp.fieldFloorPlanCategory?.[0]?.weight + 1,
      id: fp.fieldFloorPlanCategory?.[0]?.id,
    };

    // Set the default level of care.
    if ( fp.fieldLevelOfCare?.fieldDefaultLevelOfCare ) {
      defaultLevelOfCare = fp.fieldLevelOfCare?.id;
    }

    // Save fees into specific data structure.
    const roomFees = [];
    if ( fp.fieldEntranceFee1 || fp.fieldEntranceFee2 ) {
      const dataString = fp.fieldEntranceFee1 ? processPriceFieldWithOptionalRangeSerialized(
        fp.fieldEntranceFee1,
        true,
      ) : null;
      const rawNoteString = fp.fieldEntranceFee2 ? processPriceFieldWithOptionalRangeSerialized(
        fp.fieldEntranceFee2,
        false,
      ) : null;
      const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
      const roomFee = {
        label: 'Entrance Fee',
        data: dataString,
        note: noteString,
      };
      roomFees.push( roomFee );
    }
    if ( fp.fieldMonthlyFee1 || fp.fieldMonthlyFee2 ) {
      const dataString = fp.fieldMonthlyFee1 ? processPriceFieldWithOptionalRangeSerialized(
        fp.fieldMonthlyFee1,
        true,
      ) : null;
      const rawNoteString = fp.fieldMonthlyFee2 ? processPriceFieldWithOptionalRangeSerialized(
        fp.fieldMonthlyFee2,
        false,
      ) : null;
      const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
      const roomFee = {
        label: 'Monthly Fee',
        data: dataString,
        note: noteString,
      };
      roomFees.push( roomFee );
    }

    const roomDetails = fp.fieldRooms.map( ( roomData ) => ( {
      label: roomData.name,
      data: roomData.dimensions,
    } ) );

    // virtual tour
    const threeSixtiesData = fp?.fieldVirtualTour?.fieldThreeSixties;
    const threeSixties = processSerializedThreeSixties( threeSixtiesData );

    // finish collection
    const finishCollection = finishCollectionNormalizeData(
      fp?.fieldFinishCollection?.fieldFinishOption,
      included,
    );

    // furniture configurator
    const furnitureConfiguratorUrl = fp.fieldFurnitureConfiguratorUrl?.uri;

    // video
    let video = null;
    if ( fp.fieldVideo && fp.fieldVideo.fieldVideoService && fp.fieldVideo.fieldVideoId ) {
      video = {
        videoId: fp.fieldVideo.fieldVideoId,
        service: fp.fieldVideo.fieldVideoService,
        autoplay: false,
      };
    }

    // photo gallery
    const photoGalleryImages = [];
    if ( fp.fieldPhotoGallery && fp.fieldPhotoGallery.fieldGalleryPhotos ) {
      const galleryId = fp.fieldPhotoGallery.id;
      const deserializedImages = fp.fieldPhotoGallery.fieldGalleryPhotos;
      // the caption doesn't come back in the deserialized data
      // so we also have to get the raw data
      const rawGallery = responseData.included.find( ( { id } ) => id === galleryId );
      const rawPhotoGalleryImagesData = rawGallery.relationships.field_gallery_photos.data;
      deserializedImages.forEach( ( deserializedImage, i ) => {
        const rawPhotoGalleryImageData = rawPhotoGalleryImagesData[i];
        const image = {
          id: deserializedImage.id,
          image: {
            modal: rawPhotoGalleryImageData?.meta?.imageDerivatives?.links?.modal?.href,
          },
          caption: rawPhotoGalleryImageData.meta.alt,
        };
        photoGalleryImages.push( image );
      } );
    }

    return {
      id: fp.id,
      title: fp.title,
      description: fp.fieldDescription,
      floorplan2d: twoDFloorPlan,
      floorplan3d: threeDFloorPlan,
      bed: fp.fieldBedrooms,
      bath: fp.fieldBathrooms,
      studio: fp.fieldStudio,
      sqft: fp.fieldSquareFootage,
      levelsOfCare: fp.fieldLevelOfCare?.id,
      floorPlanCategory: fp.fieldFloorPlanCategory?.[0]?.id,
      roomData: roomDetails,
      roomFees,
      threeSixties,
      finishCollection,
      furnitureConfiguratorUrl,
      video,
      photoGalleryImages,
    };
  } );

  return {
    floorPlans,
    levelsOfCare,
    defaultLevelOfCare,
    floorPlanCategories,
  };
};
