import React from 'react';

const POIIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <g>
      <path d="M19,37.86C8.6,37.86,0.14,29.4,0.14,19S8.6,0.14,19,0.14S37.86,8.6,37.86,19S29.4,37.86,19,37.86z M19,3.14
        C10.25,3.14,3.14,10.25,3.14,19S10.25,34.86,19,34.86S34.86,27.75,34.86,19S27.75,3.14,19,3.14z"
      />
    </g>
    <g>
      <path d="M18.83,31.6l-1.26-1.92C16.41,27.9,10.6,18.8,10.6,14.83c0-4.54,3.69-8.23,8.23-8.23c4.54,0,8.23,3.69,8.23,8.23
        c0,3.96-5.81,13.06-6.97,14.85L18.83,31.6z M18.83,9.6c-2.88,0-5.23,2.35-5.23,5.23c0,2.01,2.84,7.3,5.23,11.21
        c2.39-3.91,5.23-9.2,5.23-11.21C24.06,11.95,21.71,9.6,18.83,9.6z"
      />
    </g>
    <circle cx="18.83" cy="15.17" r="1.83" />
  </svg>
);
export default POIIcon;
