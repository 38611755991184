import React from 'react';

import MapControlsZoomMore from './MapControlsZoomMore';
import MapControlsZoomLess from './MapControlsZoomLess';
import MapControlsZoomReset from './MapControlsZoomReset';

const MapControlsZoom = ( {
  setActiveZoom,
  activeZoom,
  zoomSettings,
} ) => (
  <>
    {zoomSettings
        && (
        <>
          <MapControlsZoomMore
            maxZoom={zoomSettings?.max}
          />
          <MapControlsZoomLess
            setActiveZoom={setActiveZoom}
            activeZoom={activeZoom}
            minZoom={zoomSettings?.min}
          />
        </>
        )}
    <MapControlsZoomReset />
  </>
);

export default MapControlsZoom;
