// This lets us know when a photo is loaded
// so that we can do dynamic stuff with CSS background images.
export const preloadPhoto = ( url ) => new Promise( ( resolve, reject ) => {
  const img = new Image();
  img.onload = () => {
    resolve( 'Success' );
  };
  img.onerror = ( err ) => {
    console.error( 'error loading image from', url );
    reject( err );
  };
  img.src = url;
} );

export const findValueByPrefix = ( object, prefix ) => {
  for ( const property in object ) {
    if (
      object.hasOwnProperty( property )
      && property.toString().startsWith( prefix )
    ) {
      return object[property];
    }
  }
};

export function objHasKeys( obj ) {
  for ( const key in obj ) {
    if ( obj.hasOwnProperty( key ) ) {
      return true;
    }
  }
  return false;
}

export async function getLatLngFromAddress( address ) {
  return new Promise( ( resolve, reject ) => {
    ( async () => {
      const encodedAddress = encodeURIComponent( address );
      const requestURL = `https://maps.googleapis.com/maps/api/geocode/json?address=1${
        encodedAddress
      }&key=AIzaSyBXESsl6mv15V9mP8bEY7iQgYr14kEjUAk`;
      try {
        const response = await fetch( requestURL );
        const data = await response.json();
        const latLng = {
          lat: data.results[0].geometry.location.lat,
          lng: data.results[0].geometry.location.lng,
        };
        resolve( latLng );
      } catch ( err ) {
        reject( err );
      }
    } )();
  } );
}

export async function getCenter( campusCenter, address ) {
  let center = null;

  // First, use the campus center entered in the CMS if available
  if ( campusCenter.lat && campusCenter.lng ) {
    center = campusCenter;
  }
  // Next, try getting the center from the address
  else if ( address ) {
    try {
      center = await getLatLngFromAddress( address );
    } catch ( err ) {
      console.error( 'Failed to get lat lng from address', err );
    }
  }
  // Default to Winston-Salem, NC
  else {
    center = {
      lat: 36.115,
      lng: -80.259,
    };
  }
  return center;
}

// add commas every 3rd number from the right
export function humanizeNumber( number ) {
  const integer = parseInt( number );
  const humanNumber = integer.toLocaleString();
  return humanNumber;
}

// add the appropriate words to the bed, bath, sqft values
export function bedBathSqftArray( studio, bed, bath, sqft ) {
  const items = [];

  if ( studio ) {
    items.push( 'Studio' );
  } else {
    if ( bed ) {
      const bedString = `${bed} Bed`;
      items.push( bedString );
    }
    if ( bath ) {
      const bathString = `${bath} Bath`;
      items.push( bathString );
    }
  }

  if ( sqft ) {
    const humanSqft = humanizeNumber( sqft );
    const sqftString = `${humanSqft} sq. ft.`;
    items.push( sqftString );
  }

  return items;
}

// join two names
export function joinNames(
  firstName1,
  lastName1,
  firstName2,
  lastName2,
  firstNameLastName2,
  separator,
) {
  let joinedNames = [];

  // the the second name all one string?
  // lets break it ito something we can use
  if ( firstNameLastName2 ) {
    const nameArray = firstNameLastName2.split( ' ' );
    // the last chunk is the last name ALMOST always
    // people like Tracy Sainte Marie break this logic, but hey
    // i gotta draw the line somewhere
    lastName2 = nameArray.pop();
    firstName2 = nameArray.join( ' ' );
  }

  // are there 2 last names?
  if ( lastName1 && lastName2 ) {
    // do the last names match?
    if ( lastName1 === lastName2 ) {
      joinedNames = `${firstName1} ${separator} ${firstName2} ${lastName1}`;
    } else {
      joinedNames = `${firstName1} ${lastName1} ${separator} ${firstName2} ${lastName2}`;
    }
  } else {
    // theres one last name
    // are there 2 first names?
    if ( firstName1 && firstName2 ) {
      joinedNames = `${firstName1} ${separator} ${firstName2} ${lastName1}`;
    } else {
      // there is only one first name, so its just one person
      joinedNames = joinedNames = `${firstName1} ${lastName1}`;
    }
  }

  return joinedNames;
}

// format entrance and monthly fees
export function processFees(
  entranceFee1,
  entranceFee2,
  monthlyFee1,
  monthlyFee2,
) {
  // Save fees into specific data structure.
  const roomFees = [];
  if ( entranceFee1 || entranceFee2 ) {
    const dataString = entranceFee1
      ? processPriceFieldWithOptionalRangeSerialized( entranceFee1, true )
      : null;
    const rawNoteString = entranceFee2
      ? processPriceFieldWithOptionalRangeSerialized( entranceFee2, false )
      : null;
    const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
    const roomFee = {
      label: 'Entrance Fee',
      data: dataString,
      note: noteString,
    };
    roomFees.push( roomFee );
  }
  if ( monthlyFee1 || monthlyFee2 ) {
    const dataString = monthlyFee1
      ? processPriceFieldWithOptionalRangeSerialized( monthlyFee1, true )
      : null;
    const rawNoteString = monthlyFee2
      ? processPriceFieldWithOptionalRangeSerialized( monthlyFee2, false )
      : null;
    const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
    const roomFee = {
      label: 'Monthly Fee',
      data: dataString,
      note: noteString,
    };
    roomFees.push( roomFee );
  }
  return roomFees;
}

// format prices from drupal
export function processPriceFieldWithOptionalRange( priceField, isSentenceCase ) {
  let processedPrice = null;
  const { price } = priceField;
  const isRange = priceField.price_is_range;
  const start = priceField.price_range_start;
  const end = priceField.price_range_end;

  if ( isRange ) {
    const humanStart = humanizeNumber( start );
    const humanEnd = humanizeNumber( end );
    if ( start && end ) {
      processedPrice = `from $${humanStart} to $${humanEnd}`;
    } else if ( start && end === null ) {
      processedPrice = `starting at $${humanStart}`;
    } else if ( end && start === null ) {
      processedPrice = `up to $${humanEnd}`;
    }
  } else {
    const humanPrice = humanizeNumber( price );
    processedPrice = `$${humanPrice}`;
  }

  if ( isSentenceCase && processedPrice ) {
    processedPrice = processedPrice.charAt( 0 ).toUpperCase() + processedPrice.slice( 1 );
  }
  return processedPrice;
}

// format prices if the drupal data is serialized
export function processPriceFieldWithOptionalRangeSerialized(
  priceField,
  isSentenceCase,
) {
  let processedPrice = null;
  const { price } = priceField;
  const isRange = priceField.priceIsRange;
  const start = priceField.priceRangeStart;
  const end = priceField.priceRangeEnd;

  if ( isRange ) {
    const humanStart = humanizeNumber( start );
    const humanEnd = humanizeNumber( end );
    if ( start && end ) {
      processedPrice = `from $${humanStart} to $${humanEnd}`;
    } else if ( start && end === null ) {
      processedPrice = `starting at $${humanStart}`;
    } else if ( end && start === null ) {
      processedPrice = `up to $${humanEnd}`;
    }
  } else {
    const humanPrice = humanizeNumber( price );
    processedPrice = `$${humanPrice}`;
  }

  if ( isSentenceCase && processedPrice ) {
    processedPrice = processedPrice.charAt( 0 ).toUpperCase() + processedPrice.slice( 1 );
  }
  return processedPrice;
}

export function toIsoString( date ) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = function ( num ) {
    const norm = Math.floor( Math.abs( num ) );
    return ( norm < 10 ? '0' : '' ) + norm;
  };

  return (
    `${date.getFullYear()
    }-${
      pad( date.getMonth() + 1 )
    }-${
      pad( date.getDate() )
    }T${
      pad( date.getHours() )
    }:${
      pad( date.getMinutes() )
    }:${
      pad( date.getSeconds() )
    }${dif
    }${pad( tzo / 60 )
    }:${
      pad( tzo % 60 )}`
  );
}

export function approximateMyFavoritePageUrl( fullName, nid, urlSlug ) {
  // if we can't retrieve a new person's my favorites url,
  // we can approximate it
  // as long as the nid is correct, it will work

  const publicUrl = process.env.REACT_APP_YOUR_TOUR_PUBLIC_URL;

  // I should probably do this this regex but I can never understand them.
  const name = fullName.replace( /\s/g, '-' );
  const removeCharacters = [
    '(',
    ')',
    '.',
    '\'',
    '"',
  ];
  for ( let i = 0; i < removeCharacters.length; i += 1 ) {
    name.replace( removeCharacters[i], '' );
  }
  let title = name.toLowerCase();
  title = encodeURI( title );

  // put url together
  const url = `${publicUrl}/${urlSlug}/${title}/${nid}`;

  return url;
}

export function decodeHtml( html ) {
  const txt = document.createElement( 'textarea' );
  txt.innerHTML = html;
  return txt.value;
}

export function toCamelCase( string ) {
  return string.replace( /(?:^\w|[A-Z]|\b\w)/g, ( word, index ) => ( index === 0 ? word.toLowerCase() : word.toUpperCase() ) ).replace( /\s+/g, '' );
}

export const ConditionalWrapper = ( { condition, wrapper, children } ) => ( condition ? wrapper( children ) : children );

/**
 * Drupal can be finicky about image urls, especially spaces and parenthesis.
 * Use this function to clean up a url retrieved from Drupal API.
 *
 * @param {string} url
 * @returns {string}
 */
export function cleanUrl( url ) {
  // Sometimes we get urls that were encoded and we don't want to encode them again
  const decoded = decodeURI( url );
  const cleanedUrl = encodeURI( decoded )
    .replaceAll( ' ', '%20' )
    .replaceAll( '(', '%28' )
    .replaceAll( ')', '%29' );

  return cleanedUrl;
}

export function getContrastTextColorValue( incomingHexcolor ) {
  const hexcolor = incomingHexcolor.replace( '#', '' );
  const r = parseInt( hexcolor.substr( 0, 2 ), 16 );
  const g = parseInt( hexcolor.substr( 2, 2 ), 16 );
  const b = parseInt( hexcolor.substr( 4, 2 ), 16 );
  const yiq = ( r * 299 + g * 587 + b * 114 ) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}
