import React, { useContext } from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import PIBPage from '../PIBPage/PIBPage';
import MapPage from '../MapPage/MapPage';
import MapTilesPage from '../MapTilesPage/MapTilesPage';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const NoChromeComponents = () => {
  const {
    config,
    mapConfig,
    settings,
  } = useContext( ConfigContext );

  const { isMapEmbed, isPIB, pibPath } = settings;
  const { secondaryColor } = config;

  return (

    <Switch>

      {isPIB && pibPath && (
        <Route
          path={pibPath}
          component={PIBPage}
        />
      )}

      <Route
        path="/map-tiles"
        exact
        component={MapTilesPage}
      />

      <Route
        path="/map-embed"
        exact
        render={() => isMapEmbed && (
          <MapPage
            mapData={mapConfig}
            secondaryColor={secondaryColor}
            isMapEmbed={isMapEmbed}
          />
        )}
      />

    </Switch>
  );
};

export default NoChromeComponents;
