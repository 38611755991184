import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

import OnImageDropdown from '../../OnImageDropdown/OnImageDropdown';
import { getApiData } from '../../../utils/ApiHelpers';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import MapContext from '../../MapPage/MapContext';

import './LevelsButtonList.scss';

const LevelsButtonList = ( {
  activeZoom,
  setActiveLevelSvgId,
  setInitialLevelSvgId,
  mapZoomToEnableLevels,
} ) => {
  // set states
  const [ levelsData, setLevelsData ] = useState( [] );
  const [ initialFloorInfo, setInitialFloorInfo ] = useState( {} );
  const [ allowLevels, setAllowLevels ] = useState( false );
  const [ options, setOptions ] = useState( null );

  const configContext = useContext( ConfigContext );
  const { config } = configContext;
  const { communityNid } = config;

  const mapContext = useContext( MapContext );
  const { mapData, setActiveLevelTid } = mapContext;

  useEffect( () => {
    if ( mapZoomToEnableLevels && activeZoom ) {
      const allowLevelsCheck = mapZoomToEnableLevels <= activeZoom;
      setAllowLevels( allowLevelsCheck );
    } else {
      setAllowLevels( false );
    }
  }, [
    mapZoomToEnableLevels,
    activeZoom,
  ] );

  const allowLevelsCallback = useCallback( () => {
    if ( !allowLevels ) {
      setActiveLevelSvgId( mapData.birdsEyeLevelId );
      setInitialLevelSvgId( mapData.birdsEyeLevelId );
      setActiveLevelTid( null );
    } else {
      setActiveLevelTid( initialFloorInfo.tid );
      setInitialLevelSvgId( initialFloorInfo.svgLevelId );
    }
  }, [
    allowLevels,
    initialFloorInfo,
    mapData,
  ] );

  useEffect( () => {
    allowLevelsCallback();
  }, [ allowLevelsCallback ] );

  const fetchLevelsDataCallback = useCallback( () => {
    const fetchData = async () => {
      const request = {
        entityType: 'taxonomy_term',
        contentType: 'map_levels',
        params: {
          sort: '-field_level_number',
        },
      };
      const data = await getApiData( request, communityNid );
      const normalizedLevelsData = data?.data?.map( ( item ) => {
        const isInitial = item.attributes.field_default_level;
        const svgLevelId = item.attributes.field_svg_id;
        const floorInfo = {
          uuid: item.id,
          tid: item.attributes.drupal_internal__tid,
          name: item.attributes.name,
          sortOrder: item.attributes.field_level_number,
          isInitial,
          svgLevelId,
        };
        if ( isInitial ) {
          setInitialLevelSvgId( floorInfo.svgLevelId );
          setInitialFloorInfo( floorInfo );
          setActiveLevelTid( floorInfo.tid );
          setActiveLevelSvgId( floorInfo.svgLevelId );
        }
        return floorInfo;
      } );
      setLevelsData( normalizedLevelsData );
    };
    if ( communityNid && allowLevels ) {
      fetchData();
    }
  }, [
    communityNid,
    allowLevels
  ] );

  useEffect( () => {
    fetchLevelsDataCallback();
  }, [ fetchLevelsDataCallback ] );

  // loop through levels to get btn and additional info
  useEffect( () => {
    if ( levelsData ) {
      const rawOptions = [];
      levelsData.forEach(
        ( level, index ) => {
          rawOptions[index] = {
            id: level.uuid,
            tid: level.tid,
            text: level.name,
            isDefault: level.isInitial,
            svgLevelId: level.svgLevelId,
          };
        },
      );
      setOptions( rawOptions );
    }
  }, [ levelsData ] );

  // handle option click
  const handleOptionOnClick = ( option ) => {
    setActiveLevelSvgId( option.svgLevelId );
    setActiveLevelTid( option.tid );
  };

  return allowLevels && (
    <OnImageDropdown
      className="mapFloor"
      optionsLocation="right"
      closeDropdownOnClick
      options={options}
      handleOptionOnClick={( option ) => handleOptionOnClick( option )}
    />
  );
};

export default LevelsButtonList;
