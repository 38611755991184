/* eslint-disable camelcase */
/* Drupal data is snake_case */
/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import MapContext from '../../MapPage/MapContext';
import { getAuthClient } from '../../../utils/auth';
import { mapSvgNormalizeData } from '../MapSvgNormalizeData';
import FadingContainer from '../../FadingContainer/FadingContainer';
import MapChunk from '../MapChunk';
import MapLevelShapes from '../MapLevel/MapLevelShapes';
import MapLevelLabels from '../MapLevel/MapLevelLabels';

import './MapOverlay.scss';

const auth = new getAuthClient();

const MapOverlay = () => {
  const [ svgData, setSvgData ] = useState( null );
  const [ levelMarkup, setLevelMarkup ] = useState( null );
  const [ activeLevelSvgData, setActiveLevelSvgData ] = useState( null );
  const [ unitsSvgData, setUnitsSvgData ] = useState( {} );
  const [ placesSvgData, setPlacesSvgData ] = useState( {} );
  const [ levelOtherSvgData, setLevelOtherSvgData ] = useState( null );

  const [ units, setUnits ] = useState( [] );
  const [ places, setPlaces ] = useState( [] );

  const { config } = useContext( ConfigContext );
  const { urlSlug } = config;

  const {
    mapData,
    activeLevelSvgId,
    activeLevelOfCareUuid,
    setInitialOverlayWidth,
    mapActiveUnitData,
  } = useContext( MapContext );

  // Get map svg data for all levels. We only need to do this once.
  useEffect( () => {
    const fetchData = async () => {
      const url = `/your_tour_api/map/${urlSlug}/?_format=json`;
      const data = await auth.fetchWithAuthentication( url, 'GET', null );
      const normalizedData = mapSvgNormalizeData( data );
      setSvgData( normalizedData );
    };
    if ( urlSlug ) {
      fetchData();
    }
  }, [ urlSlug ] );

  // Set active level data.
  useEffect( () => {
    if ( svgData && activeLevelSvgId ) {
      setActiveLevelSvgData( svgData.levels[activeLevelSvgId] );
    }
  }, [ svgData, activeLevelSvgId ] );

  // Set active level data individually for units, places, and other
  // that comes directly from the svg.
  const unitsSvgDataRaw = activeLevelSvgData?.units;
  const placesSvgDataRaw = activeLevelSvgData?.places;
  const levelOtherSvgDataRaw = activeLevelSvgData?.other;
  useEffect( () => {
    setUnitsSvgData( unitsSvgDataRaw );
    setPlacesSvgData( placesSvgDataRaw );
    setLevelOtherSvgData( levelOtherSvgDataRaw );
  }, [
    unitsSvgDataRaw,
    placesSvgDataRaw,
    levelOtherSvgDataRaw,
  ] );

  // Combine unit data from the map filter with data from the SVG for active level.
  useEffect( () => {
    if (
      unitsSvgData
      && Object.keys( unitsSvgData ).length > 0
      && mapActiveUnitData
      && activeLevelOfCareUuid
    ) {
      const rawDisabledUnits = [];
      const rawActiveUnits = [];
      const rawInactiveUnits = [];

      // Find out which units are active, inactive, or disabled.
      Object.keys( unitsSvgData ).forEach( ( key ) => {
        const unit = unitsSvgData[key];

        // Does this unit match any of the filter results?
        const isActive = Object.hasOwn( mapActiveUnitData, unit?.attributes?.id );
        const matchesLevelOfCare = unit?.info?.level_of_care?.uuid === activeLevelOfCareUuid;

        if ( matchesLevelOfCare && isActive ) {
          unit.displayMode = 'active';
          rawActiveUnits.push( unit );
        } else if ( matchesLevelOfCare ) {
          unit.displayMode = 'inactive';
          rawInactiveUnits.push( unit );
        } else {
          unit.displayMode = 'disabled';
          rawDisabledUnits.push( unit );
        }
      } );

      // Merge sorted arrays - we need disabled units to be first, then active,
      // then inactive because of the way the SVG stroke and fills interact in
      // neighboring units.
      const rawUnits = [
        ...rawDisabledUnits,
        ...rawActiveUnits,
        ...rawInactiveUnits,
      ];
      setUnits( rawUnits );
    } else {
      setUnits( [] );
    }
  }, [
    activeLevelOfCareUuid,
    unitsSvgData,
    mapActiveUnitData,
  ] );

  // Set places for active level.
  useEffect( () => {
    if ( placesSvgData ) {
      setPlaces( Object.values( placesSvgData ) );
    } else {
      setPlaces( [] );
    }
  }, [ placesSvgData ] );

  // Create active level markup.
  // Set unit styling data once so we don't have to fetch it for each unit.
  const birdsEyeLevelId = mapData?.birdsEyeLevelId;
  const birdsEyeMarkup = svgData?.birds_eye_markup;
  const rawNeutralUnitFill = mapData?.baseColors?.unitFill || 'white';
  const rawNeutralUnitStroke = mapData?.baseColors?.unitStroke || 'black';
  const rawPlaceFill = mapData?.baseColors?.placeFill || 'white';
  const rawPlaceStroke = mapData?.baseColors?.placeStroke || 'black';
  useEffect( () => {
    let levelShapeMarkup = <></>;
    if ( activeLevelSvgId === birdsEyeLevelId || !activeLevelSvgId ) {
      levelShapeMarkup = (
        <FadingContainer>
          <MapChunk markupString={birdsEyeMarkup} />
        </FadingContainer>
      );
    } else if ( mapActiveUnitData && Object.keys( mapActiveUnitData ).length !== 0 ) {
      // Above if forces a wait for filter data so we don't have a flash of inactive units.
      levelShapeMarkup = (
        <>
          <MapLevelShapes
            units={units}
            places={places}
            levelOtherSvgData={levelOtherSvgData}
            unitBaseStyling={{
              neutralUnitFill: rawNeutralUnitFill,
              neutralUnitStroke: rawNeutralUnitStroke,
            }}
            placeBaseStyling={{
              placeFill: rawPlaceFill,
              placeStroke: rawPlaceStroke,
            }}
          />
          <MapLevelLabels
            units={units}
            places={places}
          />
        </>
      );
    }
    setLevelMarkup( levelShapeMarkup );
  }, [
    units,
    places,
    levelOtherSvgData,
    birdsEyeLevelId,
    birdsEyeMarkup,
    rawNeutralUnitFill,
    rawNeutralUnitStroke,
    rawPlaceFill,
    rawPlaceStroke,
    mapActiveUnitData,
  ] );

  // Set universal SVG attributes.
  const { attributes_array } = svgData || {};
  const {
    version,
    x,
    y,
    width,
    height,
    viewBox,
  } = attributes_array || {};
  useEffect( () => {
    if ( width ) {
      const widthNumberOnly = parseInt( width, 10 );
      setInitialOverlayWidth( widthNumberOnly );
    }
  }, [ width ] );

  return activeLevelSvgId ? (
    <div className="svgMapWrapper">
      <svg
        className="svgMap"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        version={version || ''}
        x={x || 0}
        y={y || 0}
        width={width || 0}
        height={height || 0}
        viewBox={viewBox || '0 0 0 0'}
        style={{
          enableBackground: `new 0 0 ${x || 0} ${y || 0}`,
        }}
      >
        { svgData?.tagless_defs_markup && (
          <defs dangerouslySetInnerHTML={{ __html: svgData?.tagless_defs_markup }} />
        )}

        { svgData?.tagless_style_markup && (
          <style dangerouslySetInnerHTML={{ __html: svgData?.tagless_style_markup }} />
        )}

        <g dangerouslySetInnerHTML={{ __html: svgData?.bg_markup }} />

        { levelMarkup }

      </svg>

    </div>
  ) : ( <></> );
};

export default MapOverlay;
