import React from 'react';

import './PhotoGalleryImage.scss';

const PhotoGalleryImage = ({ className, image, caption }) => {
  const backgroundImageProperty = `url(${image})`;
  const style = {
    backgroundImage: backgroundImageProperty,
  };

  return <div className={className} style={style} />;
};

export default PhotoGalleryImage;
