import deepmerge from 'deepmerge';
import React, { useEffect, useState } from 'react';

import './OnImageDropdown.scss';

const OnImageDropdown = ( {
  className,
  optionsLocation,
  closeDropdownOnClick,
  options,
  handleOptionOnClick,
} ) => {
  const [ defaultOption, setDefaultOption ] = useState( null );
  const [ activeOption, setActiveOption ] = useState( null );
  const [ toggleClass, setToggleClass ] = useState( null );

  const handleToggle = () => {
    if ( toggleClass ) {
      setToggleClass( null );
    } else {
      setToggleClass( 'onImageDropdown__toggleAndOptions--active' );
    }
  };

  // set default option
  useEffect( () => {
    if ( options ) {
      let rawDefaultOption = null;
      // check each option for a default property
      options.forEach( ( option ) => {
        if ( option.isDefault ) {
          rawDefaultOption = option;
        }
      } );
      // if none of options have a default property,
      // set the first one to default
      if ( !rawDefaultOption ) {
        rawDefaultOption = options[0];
      }
      setDefaultOption( rawDefaultOption );
    }
  }, [ options ] );

  // set initial active option
  useEffect( () => {
    if ( defaultOption ) {
      setActiveOption( defaultOption );
    }
  }, [ defaultOption ] );

  const activeOptionMarkup = activeOption
    ? (
      <div className="onImageDropdown__activeOption">
        { activeOption.text }
      </div>
    )
    : null;

  // options && defaultOption && activeOption &&
  return (
    <div className={`${className} onImageDropdown`}>

      { optionsLocation === 'right'
        && activeOptionMarkup }

      { options && defaultOption && activeOption && (
        <div className={`onImageDropdown__toggleAndOptions ${toggleClass}`}>

          <button
            className="onImageDropdown__toggleArrowWrap"
            onClick={handleToggle}
            type="button"
          >
            <svg
              className="onImageDropdown__toggleArrow"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 38 38"
              style={{ enableBackground: 'new 0 0 38 38' }}
            >
              <polygon points="30.88,13.06 19,24.94 7.12,13.06 " />
            </svg>
          </button>

          <div className={`onImageDropdown__options onImageDropdown__options--${optionsLocation}`}>
            {options.map( ( option ) => {
              const activeClass = option.id === activeOption.id
                ? 'onImageDropdown__option--active'
                : '';
              // Only use autofocus on the map floor nav. If you try to use it in
              // a modal, the modal will automatically scroll to it.
              const autoFocus = className === 'mapFloor' && option.isDefault;
              return (
                <button
                  key={option.id}
                  className={`onImageDropdown__option ${activeClass}`}
                  type="button"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={autoFocus}
                  onClick={() => {
                    setActiveOption( option );
                    handleOptionOnClick( option );
                    if ( closeDropdownOnClick ) {
                      setToggleClass( null );
                    }
                  }}
                >
                  { option.text }
                </button>
              );
            } )}
          </div>

        </div>
      )}

      { optionsLocation === 'left'
        && activeOptionMarkup}

    </div>
  );
};

export default OnImageDropdown;
