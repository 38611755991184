import React from 'react';

const MarkersIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <g>
      <path d="M19.000244,0.136475c-10.401367,0-18.86377,8.462402-18.86377,18.86377s8.462402,18.863281,18.86377,18.863281
        s18.863281-8.461914,18.863281-18.863281S29.401611,0.136475,19.000244,0.136475z M4.994446,11.568359h14.395691L27.019531,19
        l-7.629395,7.431641H4.994324c-1.182129-2.218811-1.857849-4.746765-1.857849-7.431396
        C3.136475,16.31543,3.812195,13.787292,4.994446,11.568359z M19.000244,34.863525
        c-4.752075,0-9.011475-2.110779-11.921448-5.431885h13.530579L31.318359,19L20.609375,8.568359H7.079041
        c2.909912-3.321106,7.16925-5.431885,11.921204-5.431885c8.74707,0,15.863281,7.116699,15.863281,15.86377
        S27.747314,34.863525,19.000244,34.863525z"
      />
      <path d="M19,22.054504c1.686951,0,3.054565-1.367554,3.054565-3.054504c0-1.687012-1.367615-3.054565-3.054565-3.054565
        S15.945435,17.312988,15.945435,19C15.945435,20.686951,17.313049,22.054504,19,22.054504z"
      />
    </g>
  </svg>
);

export default MarkersIcon;
