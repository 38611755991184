import React from 'react';

const RoadmapIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 38 38"
    style={{ enableBackground: 'new 0 0 38 38' }}
    xmlSpace="preserve"
  >
    <path d="M19,0.14C8.6,0.14,0.14,8.6,0.14,19S8.6,37.86,19,37.86S37.86,29.4,37.86,19S29.4,0.14,19,0.14z M14.62,16.33h9.94
      l-7.03,7.03h-2.91V16.33z M33.19,11.95c1.06,2.13,1.67,4.52,1.67,7.05c0,1.51-0.23,2.98-0.62,4.36H21.77L33.19,11.95z M31.55,9.34
      l-5.99,5.99h-3.91V3.38C25.67,4.06,29.17,6.24,31.55,9.34z M20.66,3.22v12.11h-6.04V3.76c1.39-0.4,2.86-0.63,4.38-0.63
      C19.56,3.14,20.11,3.17,20.66,3.22z M11.62,4.97v18.4H3.76c-0.4-1.39-0.62-2.85-0.62-4.36C3.14,12.92,6.58,7.63,11.62,4.97z
      M5.55,27.36h6.08v5.67C9.13,31.71,7.03,29.75,5.55,27.36z M19,34.86c-1.52,0-2.98-0.23-4.38-0.63v-6.87h17.83
      C29.65,31.86,24.68,34.86,19,34.86z"
    />
  </svg>
);
export default RoadmapIcon;
