import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import $ from 'jquery';

import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';

import { residenceNormalizedData } from './ResidenceNormalizedData';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import MapContext from '../MapPage/MapContext';

import FadingContainer from '../FadingContainer/FadingContainer';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import FinishCollection from '../FinishCollection/FinishCollection';
import FurnitureConfigurator from '../FurnitureConfigurator/FurnitureConfigurator';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import ModalTableInfo from '../Modal/ModalTableInfo';
import FloorPlanImagesWidget from '../FloorPlan/FloorPlanImagesWidget';

const ResidenceDetail = ( {
  uuid,
  setNavBtns,
} ) => {
  const [ unitData, setUnitData ] = useState( null );

  const {
    config,
    featureFlags,
    isKiosk,
    setUnitHasDisplayableFees,
  } = useContext( ConfigContext );
  const { isMapEmbed } = useContext( MapContext );
  const { isPIB } = config;

  // What should we show? We have to take into account the Map Page, Kiosk Map
  // Page, SiteSee, and PIB.
  let showOccupancy = false;
  let showOccupants = false;
  let showPricing = false;
  if ( isKiosk ) {
    showOccupancy = featureFlags?.includes( 'kiosk_show_occupancy' );
    showOccupants = featureFlags?.includes( 'kiosk_show_occupants' );
    showPricing = featureFlags?.includes( 'kiosk_show_pricing' );
  } else if ( isMapEmbed ) {
    showOccupancy = featureFlags?.includes( 'sitesee_show_occupancy' );
    showOccupants = featureFlags?.includes( 'sitesee_show_occupants' );
    showPricing = featureFlags?.includes( 'sitesee_show_pricing' );
  } else if ( isPIB ) {
    showPricing = featureFlags?.includes( 'pib_show_pricing' );
  } else {
    showOccupancy = featureFlags?.includes( 'yt_show_occupancy' );
    showOccupants = featureFlags?.includes( 'yt_show_occupants' );
    showPricing = featureFlags?.includes( 'yt_show_pricing' );
  }

  // get unit data
  useEffect( () => {
    const fetchData = async () => {
      const include = [
        'field_floor_plan',
        'field_floor_plan.field_2d_floor_plan',
        'field_floor_plan.field_3d_floor_plan',
        'field_floor_plan.field_finish_collection',
        'field_floor_plan.field_finish_collection.field_finish_option',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_space',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_palette',
        'field_floor_plan.field_finish_collection.field_finish_option.field_media_image',
        'field_floor_plan.field_photo_gallery.field_gallery_photos',
        'field_floor_plan.field_video',
        'field_floor_plan.field_virtual_tour',
        'field_floor_plan.field_virtual_tour.field_three_sixties',
        'field_building_grouping',
        'field_level',
        'field_status',
        'field_residents',
        'field_2d_floor_plan',
        'field_3d_floor_plan',
        'field_finish_collection',
        'field_finish_collection.field_finish_option',
        'field_finish_collection.field_finish_option.field_finish_space',
        'field_finish_collection.field_finish_option.field_finish_palette',
        'field_virtual_tour.field_three_sixties',
        'field_video',
        'field_view_video',
        'field_photo_gallery.field_gallery_photos',
        'field_gallery_photos',
        'field_view_gallery.field_gallery_photos',
      ];
      const request = {
        entityType: 'node',
        contentType: 'residence',
        uuid,
        params: {
          include: include.join( ',' ),
        },
      };
      const data = await getNormalizedApiDataByUuid( request, residenceNormalizedData );
      setUnitData( data );
    };
    if ( uuid ) {
      fetchData();
    }
  }, [ uuid ] );

  // create status phrase
  // this needs to be changed to a field that is populated on the cms end
  const phraseifyStatus = ( status, residents, showOccupants ) => {
    let phrase = status;
    if ( residents && residents !== '' && status !== 'Available' && status !== undefined && showOccupants ) {
      switch ( status ) {
        case 'Sold':
          phrase = `Sold to ${residents}`;
          break;
        case 'Reserved':
          phrase = `Reserved by ${residents}`;
          break;
        case 'Occupied':
          phrase = `Occupied by ${residents}`;
          break;
        default:
          phrase = `${status} for ${residents}`;
          break;
      }
    }

    return phrase;
  };

  let info = '';
  let roomsDims = '';
  let virtualTour = '';
  let furnitureConfigurator = '';
  let video = '';
  let photoGallery = '';
  let view = null;
  let feesMarkup = null;

  if ( unitData ) {
    const statusPhrase = phraseifyStatus( unitData.status, unitData.residents, showOccupants );

    // If a unit has pricing data and can show that data, set context to show
    // that the unit has fees.
    if ( unitData.roomFees.length && showPricing ) {
      setUnitHasDisplayableFees( true );
    }

    feesMarkup = unitData?.roomFees?.length && unitData.roomFees.map( ( stat, index ) => (
      <div className="modalList__item" key={index}>
        <div>
          <span className="modalList__label">{stat.label}</span>
          :
          {' '}
          {stat.data}
        </div>
        { stat.note
          && (
          <div className="modalList__note">
            {' '}
            {stat.note}
          </div>
          )}
      </div>
    ) );

    info = (
      <>
        <div id="info-scroll" className="modalSection modalTable">
          <div className="modalList">

            {unitData.location && unitData.location !== '' && (
            <div className="modalList__item">
              <span className="modalList__label">Location:</span>
              {' '}
              {unitData.location}
            </div>
            )}

            { statusPhrase && statusPhrase !== '' && ( showOccupancy || showOccupants ) && (
            <div className="modalList__item">
              <span className="modalList__label">Status: </span>
              {statusPhrase}
            </div>
            )}

          </div>

          { unitData.description && unitData.description !== '' && (
          <div className="modalDescription">
            {unitData.description}
          </div>
          )}

        </div>

        { showPricing && unitData?.roomFees?.length ? (
          <div id="fees-scroll" className="modalSection modalTable modalSection__displayFees">
            <div className="modalList">
              {feesMarkup}
            </div>
          </div>
        ) : <></> }
      </>
    );

    if ( unitData.roomsDims && unitData.roomsDims.length > 0 ) {
      roomsDims = (
        <ModalTableInfo
          id="dimensions-scroll"
          tableInfo={unitData.roomsDims}
        />
      );
    }

    if ( unitData.virtualTour ) {
      const { threeSixties } = unitData.virtualTour;
      virtualTour = (
        <VirtualTourDetail
          id="tour-scroll"
          uuid={null}
          threeSixtiesData={threeSixties}
          className="modalSection modalSection--sixteen9"
        />
      );
    }

    if ( unitData.furnitureConfiguratorUrl ) {
      const { furnitureConfiguratorUrl } = unitData;
      furnitureConfigurator = (
        <FurnitureConfigurator
          id="configurator-scroll"
          url={furnitureConfiguratorUrl}
          className="modalSection modalSection--sixteen9 modalSection--border"
        />
      );
    }

    if ( unitData.video ) {
      video = (
        <VideoDetail
          hasId
          videoId={unitData.video.serviceVideoId}
          service={unitData.video.service}
          autoplay={false}
        />
      );
    }

    if ( unitData.photoGallery ) {
      const { images } = unitData.photoGallery;
      photoGallery = (
        <PhotoGalleryDetail
          id="slideshow-scroll"
          uuid={null}
          images={images}
          galleryClass="slideshow modalSection modalSection--gallery"
          imageClass="slideshowPhoto"
        />
      );
    }

    if ( unitData.view ) {
      view = unitData.view.map( ( item ) => {
        const { type } = item;
        let viewItem = null;
        switch ( type ) {
          case 'PhotoGallery':
            viewItem = (
              <PhotoGalleryDetail
                id={null}
                uuid={null}
                images={item.data}
                galleryClass="slideshow"
                imageClass="slideshowPhoto"
              />
            );
            break;
          case 'Video':
            viewItem = (
              <VideoDetail
                hasId={false}
                videoId={item.data.serviceVideoId}
                service={item.data.service}
                autoplay={false}
              />
            );
            break;
          default:
        }
        return viewItem;
      } );
    }
  }

  const setNavBtnsCallback = useCallback( () => {
    const navBtnsArray = () => {
      const navBtns = [];

      navBtns.push( {
        section: 'info-scroll',
        text: 'Info',
      } );

      if ( unitData?.twoDFloorPlan || unitData?.threeDFloorPlan ) {
        navBtns.push( {
          section: 'floorplan-scroll',
          text: 'Floor Plan',
        } );
      }

      if ( unitData?.roomsDims && unitData?.roomsDims.length > 0 ) {
        navBtns.push( {
          section: 'dimensions-scroll',
          text: 'Dimensions',
        } );
      }

      if ( unitData?.view ) {
        navBtns.push( {
          section: 'view-scroll',
          text: 'View',
        } );
      }

      if ( unitData?.photoGallery ) {
        navBtns.push( {
          section: 'slideshow-scroll',
          text: 'Photos',
        } );
      }

      if ( unitData?.video ) {
        navBtns.push( {
          section: 'video-scroll',
          text: 'Video',
        } );
      }

      if ( unitData?.virtualTour ) {
        navBtns.push( {
          section: 'tour-scroll',
          text: 'Tour',
        } );
      }

      if ( unitData?.furnitureConfiguratorUrl ) {
        navBtns.push( {
          section: 'configurator-scroll',
          text: 'Furniture Layout',
        } );
      }

      if ( unitData?.finishCollection && Object.keys( unitData.finishCollection ).length !== 0 ) {
        navBtns.push( {
          section: 'finishes-scroll',
          text: 'Finishes',
        } );
      }

      return navBtns;
    };
    setNavBtns( navBtnsArray );
  }, [ unitData, setNavBtns ] );

  useEffect( () => {
    setNavBtnsCallback();
  }, [ setNavBtnsCallback ] );

  return unitData && (
    <FadingContainer>
      <div>
        {info}

        <FloorPlanImagesWidget
          className="modalSection modalFloorplansContainer"
          threeD={unitData.threeDFloorPlan?.modal}
          twoD={unitData.twoDFloorPlan?.modal}
          name={unitData.title}
        />

        {roomsDims}

        { view
          && (
          <div id="view-scroll">
            { view }
          </div>
          )}

        {photoGallery}

        {video}

        {virtualTour}

        {furnitureConfigurator}

        <FinishCollection
          id="finishes-scroll"
          finishCollection={unitData.finishCollection}
          className="modalSection"
          isMapEmbed={isMapEmbed}
        />

      </div>
    </FadingContainer>
  );
};

export default ResidenceDetail;
